import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Form from "./components/Form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
	apiKey: "AIzaSyCHH5TwfCpsCMi4LXxPZkj5Y59uQuEbgI4",
	authDomain: "coupon-63925.firebaseapp.com",
	projectId: "coupon-63925",
	storageBucket: "coupon-63925.appspot.com",
	messagingSenderId: "442831358672",
	appId: "1:442831358672:web:efa915bc15a0fef572ecd5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
	return (
		<>
			<ToastContainer />
			<Form db={db} />
		</>
	);
}

export default App;
