import { FormControl, Button, FormGroup } from "@mui/material";
import { addDoc, collection, deleteDoc, DocumentData, DocumentReference, Firestore, getDocs, query, Timestamp, where, writeBatch } from "firebase/firestore";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

export default function Form({ db }: { db: Firestore }) {
	const [advanced, setAdvanced] = useState<boolean>(false);
	const codeRef = useRef<HTMLInputElement>(null);
	const dobRef = useRef<HTMLInputElement>(null);

	const handleClickAdvanced = () => {
		setAdvanced(!advanced);
	};

	const validate = async () => {
		let value = codeRef.current!.value;
		value = value.trim();
		const code = parseInt(value);
		if (code === undefined || Number.isNaN(code)) {
			toast.error("Geen geldige code");
			return;
		}
		const dob = dobRef.current?.value;
		await validateCode(code, dob);
	};

	const validateCode = async (code?: number, dob?: string) => {
		console.log(code);
		// , where("validUntil", ">=", Timestamp.fromDate(new Date())
		let wheres = [where("CouponCode", "==", code)];
		if (advanced && dob) {
			console.log("advanced");
			const parts = dob.split("/");
			if (parts.length !== 3 || parts[0].length !== 2 || parts[1].length !== 2 || parts[2].length !== 4) {
				toast.error("Geen geldige geboortedatum");
				return;
			}
			const newDob = `${parts[2]}-${parts[1]}-${parts[0]}`;
			console.log(newDob);
			const date = new Date(newDob);
			console.log(date);
			date.setHours(0, 0, 0, 0);
			wheres.push(where("dob", ">=", Timestamp.fromDate(date)));
			date.setHours(23, 59, 59, 999);
			wheres.push(where("dob", "<=", Timestamp.fromDate(date)));
		}
		console.log(wheres);
		const q = query(collection(db, "coupons"), ...wheres);
		console.log(q);
		const data = await getDocs(q);
		console.log(data);
		if (data.empty) {
			const usedCoupons = query(collection(db, "usedCoupons"), ...wheres);
			const usedData = await getDocs(usedCoupons);
			console.log(usedData);
			if (usedData.empty || usedData.size > 1) {
				toast.error("Deze coupon is niet geldig");
			} else {
				toast.error(
					`Deze coupon is al gebruikt op ${usedData.docs[0].data().UsedAt.toDate().toLocaleDateString("nl-NL", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" })}`
				);
			}
		} else if (data.size > 1) {
			toast.error("Meerdere coupons gevonden, gebruik geavanceerd om de juiste te vinden");
		} else {
			const validUntil = data.docs[0].data().validUntil.toDate();
			validUntil.setHours(23, 59, 59, 999);
			if (validUntil < new Date()) {
				toast.error("Deze coupon is niet meer geldig");
			} else {
				toast.success("Deze coupon is geldig");
			}
			invalidateCoupon(data.docs[0].data(), data.docs[0].ref);
		}
	};

	const invalidateCoupon = async (data: DocumentData, doc: DocumentReference) => {
		var document = {
			CouponCode: data.CouponCode,
			dob: data.dob,
			email: data.email,
			firstname: data.firstname,
			lastname: data.lastname,
			usedAt: Timestamp.fromDate(new Date()),
		};
		console.log(document);
		console.log(data);
		addDoc(collection(db, "usedCoupons"), document);
		await deleteCoupon(doc);
	};

	const deleteCoupon = async (doc: DocumentReference) => {
		await deleteDoc(doc);
		await deleteExpiredCoupons();
	};

	const deleteExpiredCoupons = async () => {
		const currentDate = new Date();
		const q = query(collection(db, "coupons"), where("validUntil", "<", Timestamp.fromDate(new Date(currentDate.setMonth(currentDate.getMonth() - 3)))));
		getDocs(q)
			.then(function (querySnapshot) {
				var batch = writeBatch(db);

				querySnapshot.forEach(function (doc) {
					batch.delete(doc.ref);
				});
				return batch.commit();
			})
			.then(function () {
				// Delete completed successfully
			});
	};

	return (
		<>
			<div className="py-6">
				<div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
					<div className="w-full p-8">
						<h2 className="text-2xl font-semibold text-gray-700 text-center">Gratis fles cava</h2>
						<div className="mt-4 flex items-center justify-between">
							<span className="border-b w-1/5 lg:w-1/3"></span>
							<p className="text-xs text-center text-gray-500 uppercase">Valideer</p>
							<span className="border-b w-1/5 lg:w-1/3"></span>
						</div>

						<form onSubmit={() => {}}>
							<FormGroup className="mb-5">
								<FormControl className="block mt-4">
									<input
										id="code"
										className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
										type="text"
										placeholder="Coupon code"
										ref={codeRef}
									/>
								</FormControl>
							</FormGroup>
							{advanced && (
								<FormGroup className="my-5">
									<FormControl className="block mt-4">
										<input
											id="dob"
											className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
											type="text"
											placeholder="Geboorte datum (dd/mm/jjjj)"
											ref={dobRef}
										/>
									</FormControl>
								</FormGroup>
							)}
							<FormGroup>
								<FormControl className="block mt-8">
									<button type="button" className="mt-4 bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" onClick={validate}>
										Valideer code
									</button>
								</FormControl>
							</FormGroup>
						</form>

						<div className="mt-4 flex items-center justify-between">
							<span className="border-b w-1/5 md:w-1/4"></span>
							<span className="cursor-pointer text-xs italic" onClick={handleClickAdvanced}>
								geavanceerd
							</span>
							<span className="border-b w-1/5 md:w-1/4"></span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
